import axios from "axios";
import { config } from "../config";
import { toast } from "react-toastify";

export const getSubscriptionsAction = (token) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.apiUrl}/subscription/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data;
          resolve(data);
        } else {
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });

export const getRecentProspectSearchesAction = (email, token) =>
  new Promise((resolve, reject) => {
    axios
      .post(
        `${config.apiUrl}/recent-search/`,
        { email },
        {
          headers: { Authorization: `Token ${token}` },
        }
      )
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data;
          resolve(data);
        } else {
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });

export const getSearchResultsAction = (data, token) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/filters/`, data, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data;
          resolve(data);
        } else {
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });
export const phoneCall = (token, payload) =>
   
  new Promise((resolve, reject) => {
    console.log(payload, "payload");
    axios
      .post(`${config.apiUrl}/dialer/`, payload, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        if (response.data) {
          const data = response.data;
          resolve(data);
        } else {
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });

export const sendEmails = (token, payload) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/send_mail/`, payload, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data;
          resolve(data);
        } else {
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });

export const getFiltersAction = (token) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.apiUrl}/auto-search/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data;
          data.company_industry = data.company_industry.filter(item => item !== null);
          resolve(data);
        } else {
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });

export const filterProspectsAction = (data, page, token) =>
  new Promise((resolve, reject) => {
    if (data.company_number_of_employees === "" && data.company_name === "" && data.company_country?.length === 0 && data.company_industry === "" && data.company_revenue === "" && data.company_year_founded === "" && data.contact_name === "" && data.departments === "" && data.job_title === "" && data.seniority?.length === 0) {
      resolve([{}]);
    }
    axios
      .post(`${config.apiUrl}/prospect-filter/?page=${page}`, data, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        if (response.data.code === 200) {
          const data = {
            prospects: response.data.data,
            pagination: response.data.page,
            total: response.data.total,
          };
          resolve(data);

        } else {
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });

export const showDetailAction = (data, token) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/show-details/`, data, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data;
          resolve(data);
        } else {
          toast.error(response?.data.message);
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });

export const getExport = (data, token) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.apiUrl}/export-csv/?${data}`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        let blob = new Blob([response.data]);
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Prospect.csv";
        alink.click();
        resolve();
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });

export const saveListAction = (token, data, setPopupModal, setIsLoading) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/add-list/`, data, {
        headers: { Authorization: `Token ${token}` },
      })

      .then((response) => {
        if (response.data.code === 200) {
          const { data } = response.data;
          toast.success(response?.data.message);
          setPopupModal(false);
          resolve(data);
        } else {
          setIsLoading(false);
          toast.error(response?.data.message);
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Something went wrong");
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });

export const listAction = (token) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.apiUrl}/show-list/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        if (response.data.code === 200) {
          const { data } = response.data;
          resolve(data);
        } else {
          toast.error(response?.data.message);
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });

export const notificationAction = (token) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.apiUrl}/notifications/`, {
        headers: { Authorization: `Token ${token}` },
      })

      .then((response) => {
        if (response.data.code === 200) {
          const { data } = response.data;
          resolve(data);
        } else {
          toast.error(response?.data.message);
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });

export const saveTagsAction = (token, data, setPopupModal, setIsLoading) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${config.apiUrl}/tags/`, data, {
        headers: { Authorization: `Token ${token}` },
      })

      .then((response) => {
        if (response.data.code === 200) {
          const { data } = response.data;
          toast.success(response?.data.message);
          setPopupModal(false);
          resolve(data);
        } else {
          setIsLoading(false);
          toast.error(response?.data.message);
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Something went wrong");
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });

export const getTagsAction = (token) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.apiUrl}/tags-search/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data;
          resolve(data);
        } else {
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });

export const availableCreditAction = (email, token) =>
  new Promise((resolve, reject) => {
    axios
      .post(
        `${config.apiUrl}/available_credit/`,
        { email },
        {
          headers: { Authorization: `Token ${token}` },
        }
      )
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data;
          resolve(data);
        } else {
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });

export const getTemplateList = (token) =>
  new Promise((resolve, reject) => {
    axios
      .get(
        `${config.apiUrl}/templatelist/`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      )
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data;
          resolve(data);
        } else {
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });

export const getAgentList = (token) =>
  new Promise((resolve, reject) => {
    axios
      .get(
        `${config.apiUrl}/agentlist/`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      )
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data;
          resolve(data);
        } else {
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });


export const creaditContactUsedAction = (data, token) =>

  new Promise((resolve, reject) => {
    axios
      .post(
        `${config.apiUrl}/credit_contact_used/`,
        data,
        {
          headers: { Authorization: `Token ${token}` },
        }
      )
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data;
          resolve(data);
        } else {
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });

export const attachDocument = (token, data) =>

  new Promise((resolve, reject) => {
    axios
      .post(
        `${config.apiUrl}/get_user_documents/`,
        data,
        {
          headers: { Authorization: `Token ${token}` },
        }
      )
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data;
          resolve(data);
        } else {
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });

//
export const TemplatePreferenceSavedAction = (data, token) =>

  new Promise((resolve, reject) => {
    axios
      .post(
        `${config.apiUrl}/TemplatePreferenceSaved/`,
        data,
        {
          headers: { Authorization: `Token ${token}` },
        }
      )
      .then((response) => {
        if (response?.data?.code === 201) {
          const data = response.data;
          toast.success(response.data.message)
          resolve(data);
        } else {
          console.log("err-->>>",)
          if (response?.data.message?.templates_name) {
            toast.error(response?.data.message.templates_name[0])
          } else {
            toast.error(response?.data.message)
          }
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        console.log("catch ----err--", error)
        if (error.response?.data.message?.templates_name) {
          toast.error(error.response?.data.message.templates_name)
        } else {
          toast.error(error.response?.data.message)
        }

        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });

export const GetMostRecentTemplete = (token) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.apiUrl}/most_recent_template/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data;
          resolve(data);
        } else {
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });

export const GetCompanies = (token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${config.apiUrl}/core_companies/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data;
          resolve([...data]);
        } else {
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });
}

export const GetRecentlyClosedCompanies = (token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${config.apiUrl}/recently_closed_companies/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data;
          resolve(data);
        } else {
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });
}

export const GetRecentlyAddCompanies = (token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${config.apiUrl}/recently_added_companies/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data;
          resolve(data);
        } else {
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });
}

export const GetRecentlyExploredCompanies = (token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${config.apiUrl}/recently_explored_companies/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data;
          resolve(data);
        } else {
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });
}

export const GetRecentlyAddedCompanies = (token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${config.apiUrl}/recently_added_companies/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data;
          resolve(data);
        } else {
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });
}

export const GetHotCompanies = (token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${config.apiUrl}/hot_companies/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data;
          resolve(data);
        } else {
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });
}

export const GetCompanyDetail = (token, company) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${config.apiUrl}/companies_detail/${company}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        if (response.data.code === 200) {
          const data = response.data.data;
          const prospectData = response.data.prospect_data;
          console.log(response.data, "Test");
          resolve({ companyData: data, prospectData: prospectData });
        } else {
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });
}



export const TemplateGetDataAction = (data, token, onProgress) => {
  return new Promise((resolve, reject) => {
    const interval = 20; // Progress update interval in percentage
    let lastReportedPercentage = 0;
    axios
      .post(
        `${config.apiUrl}/cstmisdmsgprv/`,
        data,
        {
          headers: { Authorization: `Token ${token}` },
          onDownloadProgress: (progressEvent) => {
            const downloadPercentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onProgress(downloadPercentage, 'download');
          },

        }
      )
      .then((response) => {
        if (response?.data?.code === 200) {
          const data = response.data.data;
          const newData = { ...data, mode_of_communication: response.data.mode_of_communication }
          resolve(newData);
        } else {
          reject(
            response?.data.error ||
            response?.data.message ||
            new Error("Internal server error.")
          );
        }
      })
      .catch((error) => {
        reject(
          error?.response?.data.error ||
          error?.response?.data.message ||
          new Error("Internal server error")
        );
      });
  });
};
