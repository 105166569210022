import React, { useState, useEffect, useMemo } from "react";
import { BounceLoader } from 'react-spinners';
import "../assets/css/discovery.css";
import logo from "../assets/img/discoverylogo.png";
import video from "../assets/img/MainVideo.mp4";
import OwlCarousel from "react-owl-carousel";
import ReactSpeedometer from "react-d3-speedometer"
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import a from "../assets/img/test-slide-img3.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useUser from "../hooks/useUser";
import useAuth from "../hooks/useAuth";
import { GetCompanies } from "../api/common"
import { GetRecentlyExploredCompanies } from "../api/common"
import { GetRecentlyAddCompanies } from "../api/common"
import { GetCompanyDetail } from "../api/common"
import { GetRecentlyClosedCompanies } from "../api/common"
import { GetHotCompanies } from "../api/common"
function Home() {

  const [loading, setLoading] = useState(true);
  const [showAllImages, setShowAllImages] = useState(false);
  const [companies, setCompanies] = useState([])
  const [resentlyExploreCompanies, setRecentlyExploredCompanies] = useState([])
  const [resentlyClosedCompanies, setRecentlyClosedCompanies] = useState([])
  const [resentlyAddedCompanies, setRecentlyAddedCompanies] = useState([])
  const [companyDetails, setCompanyDetails] = useState(null)
  const [hotCompanies, setHotCompanies] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const { user } = useAuth();
  const { userData } = useUser();
  const navigate = useNavigate()
  const {state} =useLocation()

  const options = useMemo(() => {
    return {
      items: 6,
      responsive: {
        0: {
          items: 2,
        },
        768: {
          items: 3,
        },
        992: {
          items: 6,
        },
      },
    };
  }, []);



  let rating = companyDetails?.buybeacon_rating
  let cadenapayRating = companyDetails?.cadenapay_rating

  const getAllCompanies = async () => {
    if (user?.token) {
      try {
        const response = await GetCompanies(user?.token);
        setCompanies([...response]);
        console.log(response,"111");
      } catch (error) {
        console.error("Error fetching all companies:", error.message);
      }
    } else {
      console.log("Token is not available");
    }
  };

 
  useEffect(() => {
    getAllCompanies();
    setTimeout(() => {
      getAllCompanies();
    }, 2000);
  }, [user?.token]);


  const getRecentlyExploredCompanies = async () => {
    if (user?.token) {
      try {
        const response = await GetRecentlyExploredCompanies(user?.token);
        setRecentlyExploredCompanies(response);
        console.log(response);
      } catch (error) {
        console.error("Error fetching recently explored companies:", error.message);
      }
    }
    else {
      console.log("Token is not available");
    }
  }

  useEffect(() => {
    getRecentlyExploredCompanies();
    setTimeout(() => {
      getRecentlyExploredCompanies();
    }, 2000);
  }, [user?.token]);

  const getRecentlyAddedCompanies = async () => {
    if (user?.token) {
      try {
        const response = await GetRecentlyAddCompanies(user?.token);
        setRecentlyAddedCompanies(response);
        console.log(response);
      } catch (error) {
        console.error("Error fetching recently explored companies:", error.message);
      }
    }
    else {
      console.log("Token is not available");
    }
  }

  useEffect(() => {
    getRecentlyAddedCompanies();
    setTimeout(() => {
      getRecentlyAddedCompanies();
    }, 2000);
  }, [user?.token]);

  const getCompanyDetail = async (selectedCompanyId) => {
    if (user?.token) {
      try {
        setLoading(true);
        const response = await GetCompanyDetail(user?.token, selectedCompanyId);
        const companyData = response?.companyData;
        console.log(response,"response");
        setCompanyDetails(companyData);
      } catch (error) {
        console.error("Error fetching company details:", error.message);
      } finally {
        setLoading(false);
      }
    } else {
      console.log("Token is not available");
    }
  };

  useEffect(() => {
    if (selectedCompanyId) {
      getCompanyDetail(selectedCompanyId); 
    }
   
  }, [user?.token, selectedCompanyId]);

  const getRecentlyClosedCompanies = async () => {
    console.log(user?.token, "user");
    if (user?.token) {
      let payload = {
        isClosed: true
      }
      try {
        const response = await GetRecentlyClosedCompanies(user?.token, payload);
        setRecentlyClosedCompanies(response);
        console.log(response);
      } catch (error) {
        console.error("Error fetching all companies:", error.message);
      }
    } else {

      console.log("Token is not available");
    }
  };

  useEffect(() => {
    getRecentlyClosedCompanies();
    setTimeout(() => {
      getRecentlyClosedCompanies();
    },2000)
  }, [user?.token]);

  const getHotCompanies = async () => {
    console.log(user?.token, "user");
    if (user?.token) {
      try {
        const response = await GetHotCompanies(user?.token);
        setHotCompanies(response);
        console.log(response);
      } catch (error) {
        console.error("Error fetching all companies:", error.message);
      }
    } else {

      console.log("Token is not available");
    }
  };

  useEffect(() => {
    getHotCompanies();
    setTimeout(() => {
      getHotCompanies();
    },2000)
  }, [user?.token]);

  const toggleImages = () => {
    setShowAllImages(!showAllImages);
  };

  const carouselItems = [
    { src: a, alt: "Image 1" },
    { src: a, alt: "Image 2" },
    { src: a, alt: "Image 3" },
    { src: a, alt: "Image 4" },
    { src: a, alt: "Image 5" },
    { src: a, alt: "Image 6" },
    { src: a, alt: "Image 7" },
    { src: a, alt: "Image 8" },
    { src: a, alt: "Image 9" },
    { src: a, alt: "Image 10" },
    { src: a, alt: "Image 11" },
  ];

  
  const visibleItems = showAllImages ? carouselItems.length : 6;


  const openModal = (companyId) => {
    setSelectedCompanyId(companyId);
    setIsModalOpen(true);
    getCompanyDetail(companyId)
  };

  useEffect(()=>{
    if(state?.companyName){
      openModal(state.companyName)
    }
  },[state?.companyName])

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCompanyId(null);
    setCompanyDetails(null)

  };



  const owlOptions = {
    items: 6,
    loop: true,
    margin: 10,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 6,
      },
    },

  };

  const [scrollPosition, setScrollPosition] = useState(0);

  const carouselContent = useMemo(() => {
    
    return (
      <OwlCarousel
      id="carousal"
      key={"carousal"}
      className="owl-theme d9x"
      loop={true}
      margin={10}
      nav={true}
      items={visibleItems}
      responsive={true}
      {...options}

    >
      {companies && companies.map((item, index) => (
        <div key={item.id} className="item" id="Core-Accounts-Carousel-item">
          <div className="hover-Carousel" >
            <div className="hover-Carousel-item" >
              <img src={item.company_logo} ></img>
              <div id="hover-Carousel-item-background" style={{ backgroundImage: `url(${item.hover_image})` }} >
              </div>
              <div className="blur-layer" onClick={() => openModal(item.company_name)}></div>
            </div>
          </div>
        </div> 
      ))}
    </OwlCarousel>
    );
  }, [companies, visibleItems, options]) 


  const recentcarousel = useMemo(()=>{
    return(
      <OwlCarousel
      id="carousal-1"
      key={"carousal-1"}
      className="owl-theme d9x"
      loop={true}
      margin={10}
      nav={true}
      items={visibleItems}
      responsive={true}
      {...options}
  
    >
      {resentlyAddedCompanies && resentlyAddedCompanies.map((item, index) => (
        <div key={item.id} className="item" id="Core-Accounts-Carousel-item">
          <div className="hover-Carousel" >
            <div className="hover-Carousel-item" >
              <img src={item.company_logo} ></img>
              <div id="hover-Carousel-item-background" style={{ backgroundImage: `url(${item.hover_image})` }} >
              </div>
              <div className="blur-layer" onClick={() => openModal(item.company_name)}></div>
            </div>
          </div>
        </div>
      ))}

    </OwlCarousel>
    );
  },[resentlyAddedCompanies,visibleItems, options])

  const previouslyCarousel = useMemo(()=>{
    return(
      <OwlCarousel
      id="carousal-2"
      key={"carousal-2"}
      className="owl-theme d9x"
      loop={true}
      margin={10}
      nav={true}
      items={visibleItems}
      responsive={true}
      {...options}
 
    >
      {resentlyExploreCompanies && resentlyExploreCompanies.map((item, index) => (
        <div key={item.id} className="item" id="Core-Accounts-Carousel-item">
          <div className="hover-Carousel" >
            <div className="hover-Carousel-item" >
              <img src={item.company_logo} ></img>
              <div id="hover-Carousel-item-background" style={{ backgroundImage: `url(${item.hover_image})` }} >

              </div>
              <div className="blur-layer" onClick={() => openModal(item.company_name)}></div>
            </div>
          </div>
        </div>
      ))}

    </OwlCarousel>
    );
  },[resentlyExploreCompanies,visibleItems, options])

  const HotCarousel = useMemo(()=>{

    return(

      <OwlCarousel
            id="carousal-4"
            key={"carousal-4"}
            className="owl-theme d9x"
            loop={true}
            margin={10}
            nav={false}
            items={visibleItems}
            responsive={true}
            {...options}
       
          >
            {hotCompanies && hotCompanies.map((item, index) => (
              <div key={item.id} className="item" id="Core-Accounts-Carousel-item">
                <div className="hover-Carousel" >
                  <div className="hover-Carousel-item" >
                    <img src={item.company_logo} ></img>
                    <div id="hover-Carousel-item-background" style={{ backgroundImage: `url(${item.hover_image})` }} >
                    </div>
                    <div className="blur-layer" onClick={() => openModal(item.company_name)}></div>
                  </div>
                </div>
              </div>
            ))}

          </OwlCarousel>
    );
  },[hotCompanies,visibleItems, options])

  const Closedcarousel = useMemo(()=>{
    return(

      <OwlCarousel
      id="carousal-4"
      key={"carousal-4"}
      className="owl-theme d9x"
      loop={true}
      margin={10}
      nav={true}
      items={visibleItems}
      responsive={true}
      {...options}
  
    >
      {resentlyClosedCompanies && resentlyClosedCompanies.map((item, index) => (
        <div key={item.id} className="item" id="Core-Accounts-Carousel-item">
          <div className="hover-Carousel" >
            <div className="hover-Carousel-item" >
              <img src={item.company_logo} ></img>
              <div id="hover-Carousel-item-background" style={{ backgroundImage: `url(${item.hover_image})` }} >
              </div>
              <div className="blur-layer" onClick={() => openModal(item.company_name)}></div>
            </div>
          </div>
        </div>
      ))}

    </OwlCarousel>
    );
  },[resentlyClosedCompanies, visibleItems, options])
  useEffect(() => {
    const handleScroll = () => {
      const navElement = document.querySelector("nav");
      if (navElement) {
        const currentPosition = window.pageYOffset;
        if (currentPosition > scrollPosition) {
          navElement.classList.add("scrollTop");
        } else {
          navElement.classList.remove("scrollTop");
        }
        setScrollPosition(currentPosition);
      }
    };

    const handleInitialScroll = () => {
      const navElement = document.querySelector("nav");
      if (navElement && window.pageYOffset === 0) {
        navElement.classList.remove("scrollTop");
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("load", handleInitialScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("load", handleInitialScroll);
    };
  }, [scrollPosition]);

 

  return (
    <main id="discovery">
      <section className="banner-box position-relative">
        <video
          className="video w-100"
          muted
          autoPlay
          loop
          src={video}
          allow="autoPlay allowfullscreen mute loop"
        />
        <nav className="navbar navbar-expand-lg navbar-light fixed-top justify-content-between">
          <div className="containerBox container-fluid">
            <Link className="navbar-brand">
              <img src={logo} alt="logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarNav"
            >
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <a className="nav-link" href="#">
                    Home <span className="sr-only">(current)</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={() => navigate("/dashboard")}>
                    Dashboard
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={() => navigate("/prospect")}>
                    Prospect search
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={() => navigate("/list")} >
                    Lists
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={() => navigate("/intelliConnect")}>
                    IntelliConnect
                  </a>
                </li>

                <li className="nav-item">
                  <button onClick={() => navigate("/login")} className="btn rounded-pill btn-purple signin-btn">
                    {user ? user.full_name : "Get Started"}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="video-container">
          <div className="layer"></div>
          <div className="video-container-content">
            <div className="containerBox">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="153"
                height="47"
                viewBox="0 0 153 47"
                fill="none"
              >
                <path
                  d="M65.8553 0.00158691C62.666 0.00158691 59.6067 0.521833 56.7812 1.44838C60.6662 3.59983 63.9259 6.53689 66.2773 10.0362H86.6165C88.945 6.56538 92.1787 3.64031 96.0132 1.50535C93.1465 0.535325 90.0366 0.00158691 86.7816 0.00158691H65.8553Z"
                  fill="white"
                />
                <path
                  d="M67.9541 23.0362C67.9403 10.3299 56.2656 0 41.9259 0H26.0221C11.8323 0 0.258386 10.12 0 22.6284L0.00764402 23.0497C0.0137597 35.7545 11.6885 46.0859 26.0298 46.0859H41.932C45.1213 46.0859 48.1746 45.5657 51.0046 44.6377C47.1196 42.5012 43.8523 39.5492 41.51 36.0499H26.0298C17.795 36.0499 11.0158 30.8219 10.2988 24.1877H10.2345V23.0347C10.2422 15.8667 17.3302 10.0346 26.0221 10.0346H41.9244C50.1591 10.0346 56.9459 15.249 57.6553 21.8968H57.7196L57.7272 23.0347V23.0497C57.7196 25.5085 56.8741 27.7994 55.4262 29.7529C57.7196 32.0168 61.1382 33.5056 64.9941 33.7035C66.8135 30.6255 67.8822 27.1532 67.9541 23.4575V23.0362Z"
                  fill="white"
                />
                <path
                  d="M145.304 6.80376C140.373 2.4184 133.83 0.00158691 126.864 0.00158691H110.962C96.6219 0.00158691 84.9471 10.3315 84.9395 23.0378V23.1233C84.9609 26.9449 86.0357 30.5296 87.9055 33.6916C91.7401 33.4382 95.1159 31.8925 97.3588 29.6151C95.9828 27.6765 95.1725 25.4411 95.1725 23.0528C95.1725 23.0378 95.1725 23.0378 95.1725 23.0378C95.1801 15.8698 102.268 10.0377 110.96 10.0377H126.862C135.562 10.0377 142.65 15.8713 142.657 23.0378C142.657 30.2208 135.569 36.0529 126.862 36.0529H111.14C108.812 39.5237 105.578 42.4488 101.739 44.5838C104.606 45.5523 107.716 46.0875 110.962 46.0875H126.864C141.203 46.0875 152.878 35.7561 152.892 23.0513C152.894 16.9238 150.198 11.1606 145.304 6.80376Z"
                  fill="white"
                />
                <path
                  d="M112.809 23.0362C112.809 19.1995 111.734 15.5878 109.849 12.3959C106.015 12.6493 102.633 14.195 100.388 16.4724C101.764 18.4124 102.567 20.6478 102.574 23.0362C102.574 30.2192 95.4941 36.0513 86.7869 36.0513H65.8546C57.155 36.0513 50.067 30.2177 50.067 23.0362C50.067 20.5774 50.9201 18.2865 52.3604 16.3314C50.067 14.0825 46.6484 12.5923 42.8001 12.3959C40.9088 15.5728 39.834 19.1995 39.834 23.0362C39.834 35.7425 51.5088 46.0874 65.8622 46.0874H86.7885C101.134 46.0874 112.809 35.744 112.809 23.0362Z"
                  fill="white"
                />
              </svg>
              <div className="banner-slogen">
                <h1>
                  Reveal <span>high-relevance</span> <br /> accounts &amp;
                  <span> prospects.</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Core-Accounts" className="sliderBox bg-none">
        <div className="containerBox ">
          <label>Core Accounts</label>
         {carouselContent}
        </div>
      </section>
      <section id="Recently-Added" className="sliderBox bg-none">
        <div className="containerBox">
          <label>Recently Added</label>
         {recentcarousel}
        </div>
      </section>
      <section id="Previuosly-Explored" className="sliderBox bg-none">
        <div className="containerBox">
          <label>Previously Explored</label>
         {previouslyCarousel}
        </div>
      </section>
      <section id="Hot-Accounts" className="sliderBox bg-none">
        <div className="containerBox">
          <label>Hot Accounts</label>
          {HotCarousel}
        </div>
      </section>
      <section id="Recently-Closed" className="sliderBox bg-none">
        <div className="containerBox">
          <label>Recently Closed</label>
         {Closedcarousel}
        </div>
      </section>
      <section className="getstarted d-flex">
        <div className="objects">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="368"
            height="374"
            viewBox="0 0 368 374"
            fill="none"
          >
            <path
              d="M368 6.10352e-05L-4 374V6.10352e-05H368Z"
              fill="#9D13C5"
              fill-opacity="0.5"
            />
          </svg>
          <div className="Semi-Circle"></div>
          <svg
            style={{ position: "absolute", right: "0px", top: "0px" }}
            xmlns="http://www.w3.org/2000/svg"
            width="506"
            height="182"
            viewBox="0 0 506 182"
            fill="none"
          >
            <path
              d="M506 0.000115296L0.000228882 7.69722e-05L436 182L506 0.000115296Z"
              fill="#9D13C5"
              fill-opacity="0.5"
            />
          </svg>
        </div>
        <div
          className="GetStartedContent m-auto text-center"
          style={{ zIndex: "3" }}
        >
          <h1>Reveal high-relevance accounts & prospects.</h1>
          {/* <p>Aatus error sit voluptatem accusantium doloremque laudantium,</p> */}
          {/* <button className="primary-cstm-btn">Get Started</button> */}
          <a href="mailto:portal@cadena.ae">
    <button className="primary-cstm-btn">Get Started</button>
  </a>
        </div>
      </section>
      <footer id="discovery-footer">
        <div className="containerBox">
          {/* <div className="container-fluid">
            <div className="row">
              <div className="col-lg-8 col-md-7">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <label>Company</label>
                    <ul>
                      <li>About</li>
                      <li>Newsroom</li>
                      <li>Company Assets</li>
                      <li>Contact Us</li>
                    </ul>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <label>Legal</label>
                    <ul>
                      <li>Terms and Conditions</li>
                      <li>Privacy</li>
                      <li>Cookie Preferences</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-5  d-flex justify-content-end">
                <div className="get-update">
                  <label>Be the first to get the updates</label>
                  <p>Aatus error sit voluptatem accusantium dolore</p>
                  <form>
                    <input
                      className="search w-100"
                      placeholder="Enter Email Address"
                    ></input>
                    <button className="primary-cstm-btn">Subscribe</button>
                  </form>
                </div>
              </div>
            </div>
          </div> */}
          <hr />
          <div
            className="d-flex flex-lg-row flex-column justify-content-lg-between  justify-content-center copy-right"
            style={{ padding: "15px 0 20px 0" }}
          >
            <div className="footer-logo">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="199"
                height="29"
                viewBox="0 0 199 29"
                fill="none"
              >
                <path
                  d="M31.0626 5.71606C29.8417 5.71606 28.6705 5.91439 27.5889 6.2676C29.0761 7.08776 30.3239 8.20741 31.2241 9.54139H39.0103C39.9017 8.21827 41.1396 7.10319 42.6075 6.28932C41.5101 5.91953 40.3196 5.71606 39.0735 5.71606H31.0626Z"
                  fill="#9D13C5"
                />
                <path
                  d="M31.8665 14.4972C31.8613 9.6534 27.392 5.71548 21.9025 5.71548H15.8142C10.3821 5.71548 5.95145 9.57339 5.85254 14.3418L5.85547 14.5024C5.85781 19.3456 10.3271 23.2841 15.8172 23.2841H21.9048C23.1257 23.2841 24.2946 23.0858 25.378 22.732C23.8907 21.9175 22.6399 20.7922 21.7433 19.4582H15.8172C12.6648 19.4582 10.0696 17.4652 9.79508 14.9362H9.7705V14.4966C9.77342 11.7641 12.4868 9.54081 15.8142 9.54081H21.9019C25.0543 9.54081 27.6524 11.5286 27.924 14.0628H27.9486L27.9515 14.4966V14.5024C27.9486 15.4397 27.6249 16.313 27.0706 17.0577C27.9486 17.9207 29.2573 18.4883 30.7334 18.5637C31.4299 17.3904 31.839 16.0667 31.8665 14.6578V14.4972Z"
                  fill="#9D13C5"
                />
                <path
                  d="M61.4767 8.30915C59.5892 6.63739 57.0841 5.71606 54.4175 5.71606H48.3299C42.8404 5.71606 38.3711 9.65398 38.3682 14.4978V14.5304C38.3764 15.9872 38.7878 17.3538 39.5036 18.5592C40.9716 18.4626 42.2639 17.8733 43.1225 17.0051C42.5957 16.2661 42.2855 15.414 42.2855 14.5035C42.2855 14.4978 42.2855 14.4978 42.2855 14.4978C42.2885 11.7653 45.0019 9.54196 48.3293 9.54196H54.4169C57.7473 9.54196 60.4607 11.7658 60.4636 14.4978C60.4636 17.236 57.7502 19.4593 54.4169 19.4593H48.3983C47.5069 20.7825 46.269 21.8975 44.7994 22.7114C45.8968 23.0806 47.0873 23.2847 48.3299 23.2847H54.4175C59.907 23.2847 64.3763 19.3462 64.3816 14.5029C64.3821 12.167 63.3503 9.97004 61.4767 8.30915Z"
                  fill="#9D13C5"
                />
                <path
                  d="M49.0377 14.4972C49.0377 13.0346 48.6262 11.6578 47.9045 10.441C46.4366 10.5376 45.1419 11.1268 44.2827 11.995C44.8095 12.7346 45.1168 13.5867 45.1197 14.4972C45.1197 17.2355 42.4092 19.4588 39.0759 19.4588H31.0627C27.7324 19.4588 25.0189 17.2349 25.0189 14.4972C25.0189 13.5599 25.3455 12.6866 25.8969 11.9413C25.0189 11.084 23.7102 10.5159 22.237 10.441C21.513 11.6521 21.1016 13.0346 21.1016 14.4972C21.1016 19.341 25.5709 23.2847 31.0656 23.2847H39.0765C44.5683 23.2847 49.0377 19.3416 49.0377 14.4972Z"
                  fill="#9D13C5"
                />
                <path
                  d="M92.2538 18.5288L92.295 20.8568L91.1031 20.8768C91.0876 20.8771 90.0491 20.894 88.0181 20.894C86.9891 20.894 85.7055 20.8895 84.171 20.8766C81.6957 20.8555 79.7165 20.3336 78.2885 19.3251C76.8694 18.3228 76.0879 16.8649 76.0879 15.2198C76.0879 13.5759 76.8762 12.1146 78.3076 11.1058C79.7261 10.1057 81.7556 9.57225 84.1768 9.56308C88.732 9.54592 91.0686 9.56336 91.0917 9.56349L92.2838 9.57267L92.265 11.901L91.0729 11.8919C91.05 11.8916 88.7248 11.8743 84.1857 11.8915C80.6615 11.9048 78.4724 13.1802 78.4724 15.2201C78.4724 16.1235 78.8807 16.87 79.6859 17.4387C80.6905 18.1482 82.2486 18.532 84.1915 18.5485C88.7195 18.5871 91.0392 18.5494 91.0618 18.549L92.2538 18.5288ZM104.117 8.57318L113.53 20.783H110.503L107.871 17.3801H100.363L97.7698 20.783H94.7771L104.117 8.57318ZM102.169 15.0286H106.065L104.117 12.4923L102.169 15.0286ZM130.866 11.6947C131.846 12.6657 132.375 13.9097 132.356 15.1974C132.333 16.7914 131.546 18.246 130.141 19.2933C128.677 20.384 126.617 20.9605 124.183 20.9605C119.538 20.9605 117.2 20.8937 117.177 20.893L116.009 20.8589V9.4674H124.183C127.848 9.4674 129.84 10.6786 130.866 11.6947ZM124.184 18.6093C128.131 18.6093 129.924 16.8789 129.949 15.1642C129.959 14.5014 129.683 13.8727 129.151 13.346C128.157 12.3615 126.393 11.8194 124.184 11.8194H118.418V18.5673C119.536 18.5863 121.457 18.6093 124.184 18.6093ZM152.154 11.8189L138.214 11.819V14.0088L147.511 14.0426L147.502 16.3942L138.214 16.3604V18.5422H152.153V20.8937H135.806V9.46765H152.153L152.154 11.8189ZM169.521 9.45661H171.929V20.7249L157.99 13.6154V20.8826H155.582V9.61437L169.522 16.7239L169.521 9.45661ZM183.734 8.57318L193.147 20.783H190.12L187.488 17.3801H179.98L177.387 20.783H174.394L183.734 8.57318ZM181.786 15.0286H185.682L183.734 12.4923L181.786 15.0286Z"
                  fill="#9D13C5"
                />
              </svg>
            </div>
            <div className="d-flex">
              <p>copyright@2023. All rights reserved</p>
            </div>
          </div>
        </div>
      </footer>
      <>
        {loading && (

          <div className="spinner-container">
            <BounceLoader color={'#ffffff'} loading={loading} size={60} />
            <div className="spinner"></div>
          </div>
        )}
 
        {(!loading && isModalOpen && selectedCompanyId&&companyDetails) && (
          <div
            className="modal show"
            style={{ zIndex: "9999", background: "rgb(0 0 0 / 88%)" }}
          >
            <div className="modal-dialog  modal-dialog-centered modal-lg ">
              <div className="modal-content p-lg-5 p-3 text-white shortbreef-modal position-relative overflow-hidden">
                <div className="bg-video position-absolute">
                  {console.log(companyDetails?.company_gif,"companyDetails in modal")}
                  <img src={companyDetails?.company_gif} alt="bg-video" className="w-100" />
                  {/* <img src={bgVideo} alt="bg-video" className="w-100" /> */}
                </div>
                <div className="modal-header border-0 bg-none">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    onClick={closeModal}
                  ></button>
                </div>
                <div className="modal-body border-0 p-lg-5 -sm-3">
                  <div className="row">
                    <div className="col-12">
                      <div className="company-logo mb-lg-4 mb-lg-5 mb-md-3 mb-3">
                        <img className="openModal-company-logo" src={companyDetails?.company_logo} alt="companyLogo" />
                      </div>
                    </div>
                    <div className="col-12">
                      <Link
                        style={{ fontSize: "13px" }}
                        to={`/discovery/detail/${selectedCompanyId}`}
                        className="btn d-inline-flex align-items-center gap-4 mb-lg-5 mb-md-4 mb-3 btn-link"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                        >
                          <path
                            d="M10.5 1.79919C5.67268 1.79919 1.74998 5.83398 1.75 10.7992C1.74998 15.7645 5.67268 19.7992 10.5 19.7992C15.3273 19.7992 19.25 15.7645 19.25 10.7992C19.25 5.83398 15.3273 1.79919 10.5 1.79919ZM10.5 2.69919C14.8544 2.69919 18.375 6.32043 18.375 10.7992C18.375 15.278 14.8544 18.8992 10.5 18.8992C6.1456 18.8992 2.62499 15.278 2.625 10.7992C2.62499 6.32043 6.1456 2.69919 10.5 2.69919Z"
                            fill="black"
                          />
                          <path
                            d="M11.375 10.7999C11.375 11.0386 11.2828 11.2676 11.1187 11.4363C10.9546 11.6051 10.7321 11.6999 10.5 11.6999C10.2679 11.6999 10.0454 11.6051 9.88128 11.4363C9.71719 11.2676 9.625 11.0386 9.625 10.7999C9.625 10.5612 9.71719 10.3324 9.88128 10.1636C10.0454 9.99479 10.2679 9.8999 10.5 9.8999C10.7321 9.8999 10.9546 9.99479 11.1187 10.1636C11.2828 10.3324 11.375 10.5612 11.375 10.7999Z"
                            fill="black"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M6.1025 6.75096C6.02699 6.75496 5.95377 6.77905 5.88999 6.82083C5.82621 6.86261 5.77405 6.92067 5.73859 6.98936C5.70313 7.05806 5.68559 7.13508 5.68767 7.21282C5.68974 7.29057 5.71138 7.3664 5.75045 7.43299L8.72409 12.4744C8.77598 12.5623 8.8556 12.6293 8.94967 12.6642L14.726 14.8228C14.8109 14.8544 14.9032 14.8583 14.9903 14.8338C15.0774 14.8093 15.155 14.7578 15.2125 14.6862C15.2699 14.6145 15.3044 14.5264 15.3113 14.4338C15.3181 14.3412 15.297 14.2487 15.2507 14.1689L12.3403 9.16092C12.2887 9.07232 12.2091 9.00467 12.1147 8.96932L6.27511 6.77733C6.21986 6.7568 6.16116 6.74765 6.1025 6.75096ZM7.14498 8.06229L11.6687 9.76033L13.8681 13.5449L9.3923 11.8715L7.14498 8.06229Z"
                            fill="black"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10.0625 3.59924V5.39924H10.9375V3.59924H10.0625Z"
                            fill="black"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M15.75 10.3492V11.2492H17.5V10.3492H15.75Z"
                            fill="black"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10.0625 16.1992V17.9992H10.9375V16.1992H10.0625Z"
                            fill="black"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.5 10.3492V11.2492H5.25V10.3492H3.5Z"
                            fill="black"
                          />
                        </svg>
                        Explore Company
                      </Link>
                    </div>

                    <div className="col-12 mt-lg-5 mt-md-4 mt-3">

                      <div className="row">
                        <div className="col-lg-8 col-md-7 pe-md-5">
                          <div className="short-detail-box mb-lg-4 mb-md-4 mb-3">
                            <h3>About</h3>
                            <p>
                              {companyDetails?.company_descriptions}
                            </p>
                          </div>
                          <div className="short-detail-box mb-lg-4 mb-md-4 mb-3">
                            <h3>Revenue</h3>
                            <p>
                              {companyDetails?.annual_revenue}
                            </p>
                            <h3>T-Shirt Size</h3>
                            <p>{companyDetails?.T_Shirt_Size}</p>
                          </div>
                          <div className="short-detail-box mb-lg-4 mb-md-4 mb-3">
                            <h3>Employees</h3>
                            <p>{companyDetails?.company_employees}</p>
                          </div>
                          <div className="short-detail-box mb-lg-4 mb-md-4 mb-3">
                            <h3>Industry</h3>
                            <p>{companyDetails?.company_industry}</p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-5">
                          <div className="speedometer-box list-unstyled">
                            <div className="d-flex speedometer-box-card">
                              <label style={{ color: "white" }}>
                                BuyBeacon
                              </label>
                              <div className="speedometer-div">
                                <ReactSpeedometer
                                  minValue={0}
                                  maxValue={10}
                                  value={rating}
                                  segments={5}
                                  segmentColors={["red", "orange", "yellow", "lightgreen", "green"]}
                                  ringWidth={40}
                                  needleHeightRatio={0.7}
                                  needleColor="gray"
                                  textColor="black"
                                />
                              </div>
                            </div>
                            <div className="d-flex speedometer-box-card">
                              <label>
                                CadenaPay
                              </label>
                              <div className="speedometer-div">
                                <ReactSpeedometer
                                  minValue={0}
                                  maxValue={10}
                                  value={cadenapayRating}
                                  segments={5}
                                  segmentColors={["red", "orange", "yellow", "lightgreen", "green"]}
                                  ringWidth={40}
                                  needleHeightRatio={0.7}
                                  needleColor="gray"
                                  textColor="black"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </main>

  );
}

export default Home;
